import { FeatureEnabledResult } from "../../queries/useFeature"
import { Sticker } from "../../types/Sticker"
import LegendItem from "../LegendItem"

type Props = {
    title: string
    stickers: Sticker[]
    clickable?: boolean
    onClick?: (s: Sticker) => void
    selectionEnabled?: FeatureEnabledResult
}

export const LegendGrid = ({
    title,
    stickers,
    clickable,
    selectionEnabled,
    onClick
}: Props) => {
    return (
        <div className="md:py-8 py-2">
            <h2 className="md:text-2xl text-xl font-bold md:mb-8 mb-4 px-2">{title}</h2>
            <div className="flex gap-4 grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1">
                {stickers.map((m, i) => (
                    <LegendItem
                        clickable={clickable}
                        onClick={() => onClick && onClick(m)}
                        selectionEnabled={selectionEnabled}
                        key={i}
                        {...m}
                    />)
                )}
            </div>
        </div>
    )
}