import { Dialog } from "@mui/material"
import { useMemo } from "react"
import { BiX } from "react-icons/bi"
import { Sticker } from "../types/Sticker"
import { LegendGrid } from "./LegendGrid"
import { FeatureEnabledResult } from "../queries/useFeature"

type Props = {
    stickers: Sticker[]
    onSelect: (s?: Sticker) => void
    opened?: boolean
    onClose: () => void
    selectionEnabled?: FeatureEnabledResult
}

export default function LegendPickerDialog({
    stickers: stickersProp,
    onSelect,
    opened,
    onClose,
    selectionEnabled
}: Props) {
    const stickers = useMemo<Sticker[]>(() => {
        return [{
            icon: <BiX className="w-full h-full" />,
            type: 'subject',
            name: 'None',
            description: 'No attribute selected'
        }, ...stickersProp]
    }, [stickersProp])

    return (
        <Dialog
            open={!!opened}
            onClose={onClose}
            maxWidth="lg"
            fullWidth
        >
            <div className="md:py-2 md:px-8 py-4 px-4">
                <LegendGrid
                    title="Optionally, select one attribute for your interest"
                    stickers={stickers}
                    selectionEnabled={selectionEnabled}
                    clickable
                    onClick={onSelect}
                />
            </div>
        </Dialog>
    )
}