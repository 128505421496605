import { useCallback, useMemo } from 'react'
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import { TextField } from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers';
import { min } from 'date-fns';

export type TimeRange = {
    start?: Date
    end?: Date
}

type Props = {
    value?: TimeRange
    onChange: (range: TimeRange) => void,
    errors?: any
    minTime?: Date
    maxTime?: Date
    disabled?: boolean
}

export default function TimeSpanPicker({
    value: valueProp,
    onChange,
    errors,
    disabled,
    minTime: minTimeProp,
    maxTime: maxTimeProp
}: Props) {
    const value = useMemo(() => {
        return {
            start: valueProp?.start,
            end: valueProp?.end
        } as TimeRange
    }, [valueProp])

    const setStart = useCallback((t: any) => {
        onChange({
            ...value,
            start: t,
        })
    }, [value, onChange])

    const setEnd = useCallback((t: any) => {
        onChange({
            ...value,
            end: t,
        })
    }, [value, onChange])

    const startMaxtime = useMemo(() => {
        const arr = []
        if (maxTimeProp) arr.push(maxTimeProp)
        if (value.end) arr.push(value.end)

        return min(arr)
    }, [maxTimeProp, value.end])

    const endMinTime = useMemo(() => {
        const arr = []
        if (minTimeProp) arr.push(minTimeProp)
        if (value.start) arr.push(value.start)

        return min(arr)
    }, [minTimeProp, value.start])


    return (
        <div className="flex gap-4 max-w-[300px] w-full">
            <div className='flex flex-col flex-1'>
                <TimePicker
                    value={value.start ?? null}
                    maxTime={startMaxtime}
                    minTime={minTimeProp ?? undefined}
                    disabled={disabled}
                    onChange={setStart}
                    renderInput={props => <TextField {...props} />}
                />
                <div className="text-sm text-red-400">
                    {errors?.start}
                </div>
            </div>

            <div className='flex flex-col flex-1'>
                <TimePicker
                    value={value?.end ?? null}
                    minTime={endMinTime}
                    disabled={disabled}
                    maxTime={maxTimeProp ?? undefined}
                    onChange={setEnd}
                    renderInput={props => <TextField {...props} />}
                />
                <div className="text-sm text-red-400">
                    {errors?.end}
                </div>
            </div>
        </div>
    )
}