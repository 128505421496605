import { useParams } from "react-router-dom";
import { API_BASE } from "../constants/api";
import { LegendParams } from "../types/common";
import { useCommonFetchQuery } from "./useCommonQuery";
import useFeature from "./useFeature";

export const useRating = () => {
    const { legendId, sessionId } = useParams<LegendParams>()
    const featureStatus = useFeature('rating')

    return useCommonFetchQuery(
        ['rating', legendId],
        `${API_BASE}/rating/${legendId!}/s/${sessionId}`,
        Boolean(legendId && sessionId && featureStatus === 'enabled'),
    )
}