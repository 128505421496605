export const baseFetch = async <T>(inputParam: RequestInfo | URL, init?: RequestInit): Promise<T | undefined> => {
    async function req() {
        if (inputParam instanceof URL) {
            return fetch(inputParam, init)
        }

        if (typeof inputParam === 'string') {
            return fetch(inputParam, {
                ...init,
                headers: new Headers({
                    ...init?.headers,
                    'Content-Type': 'application/json',
                })
            })
        }

        const input: RequestInfo = {
            ...inputParam,
            url: inputParam.url,
            headers: new Headers({
                ...inputParam.headers,
                'Content-Type': 'application/json',
            })
        }
        return fetch(input, init)
    }

    const resp = await req()
    if (init?.method !== 'GET') return

    if (!resp.ok) throw resp.statusText

    const json = (await resp.json())

    return json
}