import { useSnackbar } from "notistack"
import { useQueryClient } from "react-query"
import { useParams } from "react-router-dom"
import { API_BASE } from "../constants/api"
import { LegendParams } from "../types/common"
import { baseFetch } from "../utils/baseFetch"
import { useCommonFetchMutation } from "./useCommonMutation"

type Input = {
    rating: number
    message?: string
}

export const useSetRating = () => {
    const { legendId, sessionId } = useParams<LegendParams>()
    const { enqueueSnackbar } = useSnackbar()
    const client = useQueryClient()

    return useCommonFetchMutation<Input>(
        async ({ message, rating }) => {
            await baseFetch(`${API_BASE}/rating/${legendId!}/s/${sessionId}`, {
                method: 'POST',
                body: JSON.stringify({
                    message,
                    rating
                })
            })
        },
        Boolean(legendId && sessionId),
        {
            onSuccess: async () => {
                enqueueSnackbar('Feedback is sent', {
                    variant: 'success'
                })
                await client.refetchQueries('rating')
            }
        }
    )
}