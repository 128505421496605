import { useQuery } from "react-query"
import { useErrorSnackbar } from "../utils/useErrorSnackbar"

export const useCommonQuery = <T>(
    cacheKey: any[],
    cb: () => Promise<T>) => {
	const showError = useErrorSnackbar()

    const {
        isLoading,
        data,
        error
    } = useQuery(
        cacheKey,
        async () => cb(),
        { onError: err => showError(err) }
    )

    return [data, isLoading, error] as const
}

export const useCommonFetchQuery = <T = any>(
    cacheKey: (string | undefined)[],
    endpoint: string,
    shouldRequest: boolean) => {
	const showError = useErrorSnackbar()

    const {
        isLoading,
        data,
        error
    } = useQuery(
        cacheKey,
        async () => {
            if (!shouldRequest) return

            const res = await fetch(endpoint)
            if (!res.ok) throw Error('Invalid response')

            return await res.json() as T
        },
        { onError: err => showError(err) }
    )

    return [data, isLoading, error] as const
}