import { ButtonHTMLAttributes, DetailedHTMLProps } from 'react'
import cx from 'classnames'

export const ActionButton = (props: DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>) => (
    <button {...props} className={cx(`
        bg-primary-500 text-white rounded-lg px-4 py-1 disabled:opacity-50 w-fit
        disabled:cursor-not-allowed shadow transition whitespace-nowrap
        hover:shadow-primary-500`, props.className)} />
)

export const Logo = () => (
    <div className="relative flex">
        <img className="h-full" alt="logo" src="/assets/logo.svg" />

        <span className="absolute -right-7 bottom-0 translate-y-1/2 bg-primary-500 text-white px-2 py-[1px] shadow text-xs rounded">Beta</span>
    </div>
)