import { v4 as uuid } from 'uuid'

const key = 'ee-session'

export const SESSION = {
    get() {
        return localStorage.getItem(key)
    },
    generate() {
        const id = uuid()

        localStorage.setItem(key, id)

        return id
    },
    createOrGenerate() {
        if (SESSION.get()) {
            return SESSION.get() ?? undefined
        } else {
            return SESSION.generate()
        }
    }
}

export default SESSION
