import styled from '@emotion/styled';
import { useCallback, useMemo } from 'react';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

const StyledPicker = styled(DateRangePicker)`
    .rdrDayNumber span {
        font-weight: 400;
    }
`

export type Range = {
    startDate: Date,
    endDate: Date
}

type Props = {
    value?: Range,
    onChange: (val: Range) => void
    disabledDates: Date[]
}

export default function DateSpanPicker({
    value: valueProp,
    onChange,
    disabledDates
}: Props) {
    const handleSelect = useCallback((e: any) => {
        onChange(e.selection)
    }, [onChange])

    const value = useMemo(() => {
        return {
            ...valueProp,
            key: 'selection',
        }
    }, [valueProp])

    return (
        <StyledPicker
            ranges={[value]}
            onChange={handleSelect}
            disabledDates={disabledDates}
        />
    )
}