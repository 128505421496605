import { useParams } from "react-router-dom"
import { API_BASE } from "../constants/api"
import { LegendParams } from "../types/common"
import { baseFetch } from "../utils/baseFetch"
import { useCommonFetchMutation } from "./useCommonMutation"

type Input = {
    subjectName: string
    attrName?: string
}

export const useAddSelection = () => {
    const { legendId, sessionId } = useParams<LegendParams>()

    return useCommonFetchMutation(
        async ({ subjectName, attrName }: Input) => {
            await baseFetch(`${API_BASE}/selections/${legendId}/s/${sessionId}`, {
                method: 'POST',
                body: JSON.stringify({
                    sessionId,
                    subjectName,
                    attrName
                })
            })
        },
        Boolean(legendId && sessionId)
    )
}