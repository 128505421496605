import { useSnackbar } from "notistack"
import { useQueryClient } from "react-query"
import { useParams } from "react-router-dom"
import { RangeType } from "../../components/admin/settings/ScheduleSection"
import { API_BASE } from "../../constants/api"
import { LegendParams } from "../../types/common"
import { baseFetch } from "../../utils/baseFetch"
import { useCommonFetchMutation } from "../useCommonMutation"

export const useSetLegend = () => {
    const { legendId } = useParams<LegendParams>()
    const { enqueueSnackbar } = useSnackbar()
    const client = useQueryClient()

    return useCommonFetchMutation<{ attendees: number, schedule: RangeType[] }>(
        async ({ attendees, schedule }) => {
            await baseFetch(`${API_BASE}/settings/${legendId}`, {
                method: 'POST',
                body: JSON.stringify({
                    legendId,
                    attendees,
                    schedule
                })
            })
        },
        Boolean(legendId),
        {
            onSuccess: async () => {
                await client.refetchQueries('legend-settings')
                enqueueSnackbar('Settings are saved', {
                    variant: 'success'
                })
            }
        }
    )
}