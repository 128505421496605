import { useMutation, UseMutationOptions } from "react-query"
import { useErrorSnackbar } from "../utils/useErrorSnackbar"

export const useCommonFetchMutation = <TInput>(
    cb: (input: TInput) => Promise<void>,
    enabled: boolean,
    config: UseMutationOptions<void, Error, TInput> = {}
) => {
	const showError = useErrorSnackbar()

    const {
        isLoading,
        mutate
    } = useMutation<void, Error, TInput>(
        async (input: TInput) => {
            if (!enabled) return

            return await cb(input)
        }, {
            onError: showError,
            ...config
        }
    )

    return [mutate, isLoading] as const
}