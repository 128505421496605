import { FormControl, ListItemText, MenuItem, Select, SelectChangeEvent } from "@mui/material"
import { useCallback } from "react"
import { Sticker } from "../../types/Sticker"
import InputBase from '@mui/material/InputBase'
import styled from "@emotion/styled"

type Props = {
    stickers: Sticker[]
    value?: string[]
    onSelect: (names: string[]) => void
}

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    '& .MuiInputBase-input': {
      borderRadius: 4,
      border: '1px solid #eee',
      fontSize: 16,
      padding: '10px 26px 10px 12px',
      boxShadow: 'var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);'
      // Use the system font instead of the default Roboto font.
    },
  }));

export const StickerMultiselect = ({
    stickers,
    value,
    onSelect
}: Props) => {
    const handleChange = useCallback((e: SelectChangeEvent<string[]>) => {
        const { value } = e.target

        onSelect(value as string[])
    }, [onSelect])

    return (
        <div className="flex w-68 gap-2" role="search">
            <FormControl className="w-60">
                <div className="text-sm text-gray-600 mb-3">
                    Filter
                </div>
                <Select
                    labelId="preferences-select"
                    label="Filter"
                    multiple
                    renderValue={(s: string[]) => s.join(', ')}
                    value={value}
                    onChange={handleChange}
                    input={<BootstrapInput className="h-12 w-full rounded-lg bg-white text-left sm:text-sm" />}
                >
                    {stickers.map(s => (
                        <MenuItem key={s.name} value={s.name} className="flex gap-2">
                            <div className="bg-[#e8e8e8] relative rounded-lg flex shrink-0 justify-center items-center p-1 w-8 shadow">
                                <img src={s.url} alt={s.name} />
                            </div>
                            <ListItemText primary={s.name} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <div className="h-full pt-11 pl-4">
                {!!value?.length && <button className="underline" onClick={() => onSelect([])}>Reset</button>}
            </div>
        </div>
    )
}