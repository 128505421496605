import { useParams } from "react-router-dom"
import { API_BASE } from "../constants/api"
import { LegendParams } from "../types/common"
import { useCommonFetchQuery } from "./useCommonQuery"
import useFeature from "./useFeature"

export const useSelections = () => {
    const { legendId, sessionId } = useParams<LegendParams>()
    const featureStatus = useFeature('selection')

    return useCommonFetchQuery(
        ['selections', legendId, sessionId],
        `${API_BASE}/selections/${legendId}/${sessionId}`,
        Boolean(legendId && sessionId && featureStatus === 'enabled')
    )
}