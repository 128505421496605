import { useMemo } from "react"
import { Combo } from "../types/Combo"
import { Sticker } from "../types/Sticker"

type Props = {
    subjects: Sticker[]
    attrs: Sticker[]
}

const LegendExample = ({
    subjects,
    attrs
}: Props) => {
    const topLeft = useMemo<Combo | undefined>(() => {
        if (subjects.length < 1) return
        
        return {
            subject: subjects[0],
            attr: attrs[0]
        }
    }, [subjects, attrs])

    const topRight = useMemo<Combo | undefined>(() => {
        if (subjects.length < 2) return
        
        return {
            subject: subjects[1],
            attr: attrs[1]
        }
    }, [subjects, attrs])

    const bottomRight = useMemo<Combo | undefined>(() => {
        if (subjects.length < 3) return
        
        return {
            subject: subjects[2],
            attr: attrs[2]
        }
    }, [subjects, attrs])

    return (
        <div className="bg-[#e8e8e8] aspect-video max-w-xl w-full p-8 flex flex-col items-center justify-center gap-4 relative shadow-md md:mb-10">
            <h2 className="text-3xl">
                John Doe
            </h2>

            <span>Lorem ipsum</span>

            {topLeft && (
                <div className={`absolute left-4 top-6 w-20 -rotate-[20deg]`}>
                    <img src={topLeft.subject.url} className="md:w-16 w-14 absolute" alt={topLeft.subject.name} />
                    {topLeft.attr && <img src={topLeft.attr.url} className="md:w-12 w-10 absolute translate-y-1/2 translate-x-1/2" alt={topLeft.attr.name} />}
                </div>
            )}

            {topRight && (
                  <div className={`absolute right-4 md:top-12 top-6 w-20 rotate-[10deg]`}>
                    <img src={topRight.subject.url} className="md:w-16 w-14 absolute" alt={topRight.subject.name} />
                    {topRight.attr && <img src={topRight.attr.url} className="md:w-12 w-10 absolute translate-y-1/2 translate-x-1/2" alt={topRight.attr.name} />}
                </div>
            )}

            {bottomRight && (
                  <div className={`absolute right-4 md:bottom-24 bottom-20 w-20 rotate-[20deg]`}>
                    <img src={bottomRight.subject.url} className="md:w-16 w-14 absolute" alt={bottomRight.subject.name} />
                    {bottomRight.attr && <img src={bottomRight.attr.url} className="md:w-12 w-10 absolute translate-y-1/2 translate-x-1/2" alt={bottomRight.attr.name} />}
                </div>
            )}
        </div>
    )
}

export default LegendExample
