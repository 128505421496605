import { Dialog } from "@mui/material";
import { isWithinInterval } from "date-fns";
import { useCallback, useMemo, useState } from "react";
import { ActionButton } from "../../common";
import ScheduleDatePicker, { Range } from "../../DateSpanPicker";
import { RangeType } from "./ScheduleSection";

type Props = {
    opened?: boolean
    onClose?: () => void
    onAdd: (range: Range) => void
    ranges: RangeType[]
}

export default function DateDialog({
    opened,
    onClose,
    onAdd,
    ranges
}: Props) {
    const [range, setRange] = useState<Range>({
        startDate: new Date(),
        endDate: new Date()
    })

    const handleAdd = useCallback(() => {
        if (!range) return

        onAdd(range)
    }, [range, onAdd])

    const isRangeAllowed = useMemo(() => {
        return !ranges.some(r => range && isWithinInterval(r.date, { start: range.startDate, end: range.endDate }))
    }, [range, ranges])

    return (
        <Dialog
            open={!!opened}
            onClose={onClose}
        >
            <div className="flex flex-col p-4">
                <ScheduleDatePicker value={range} onChange={setRange} disabledDates={ranges.map(r => r.date)} />

                <div className="flex px-4">
                    <ActionButton
                        disabled={!isRangeAllowed}
                        onClick={handleAdd}
                        className="ml-auto">
                            Apply
                    </ActionButton>
                </div>
            </div>
        </Dialog>
    )
}