import { FiMinus, FiPlus } from "react-icons/fi"
import { FetcherWithComponents } from "react-router-dom"
import { Combo } from "../../types/Combo"
import { LoaderOverlay } from "../LoaderOverlay"
import { SelectionPlaceholder } from "./placeholder"

type Props = {
    selections: Combo[]
    loading?: boolean
    updating?: boolean
    fetcher: FetcherWithComponents<any>
    onRemove: (name: string) => void
}

const selectionPlaceholders =  Array(5).fill("").map((_, i) => <SelectionPlaceholder key={`placeholder-${i}`} />)

const SelectedArea = ({
    selections,
    loading,
    updating,
    onRemove
}: Props) => {
    return (
        <div className="flex md:h-28 h-24 shadow-md bg-white p-4 gap-4 fixed bottom-0 w-full border-t border-[#eee] overflow-auto">
            {loading ? selectionPlaceholders : (
                <>
                    {selections.length === 0 && (
                        <div className="w-full h-full flex items-center justify-center">
                            Select sticker by tapping on the
                            <button
                                className="bg-[#008000] inline-block mx-1 p-1 text-white rounded-full -top-2 -right-2"
                            >
                                <FiPlus />
                            </button>
                            button.
                        </div>
                    )}

                    {selections.map((p, i) => (
                        <div key={`selection-${i}`} className="aspect-square relative rounded-lg flex justify-center items-center px-4 md:w-28 w-20 shrink-0">
                            <img src={p.subject?.url} alt={p.subject?.name} className="h-full object-contain" />
                            {p.attr?.url && <img className="absolute md:w-12 w-8 right-0 bottom-0" src={p.attr.url} alt={p.attr?.name} />}

                            <button
                                className="bg-[#e62315] p-1 text-white rounded-full -top-2 -right-2 absolute transition hover:scale-150"
                                onClick={() => onRemove(p.subject?.name!)}
                            >
                                <FiMinus />
                            </button>
                        </div>
                    ))}

                    {updating && <LoaderOverlay />}
                </>
            )}
        </div>
    )
}

export default SelectedArea
