import { format, parse } from "date-fns"

export function formatTime(date?: Date) {
    if (!date) return ''
    return format(date, 'HH:mm')
}

export function parseTime(str: string) {
    return parse(str, 'HH:mm', new Date())
}

export function formatDate(date?: Date) {
    if (!date) return ''

    return format(date, 'dd/MM/yyyy, EEEE')
}
