import PageContainer from "../components/PageContainer"

const NotFound = () => {
    if (process.env.NODE_ENV === 'production') {
        window.location.href = '/what'
        return <></>
    }

    return (
        <PageContainer>
            <h2 className="md:text-2xl text-xl font-bold md:mb-8 mb-4 px-2 text-center">Page not found</h2>
        </PageContainer>
    )
}

export default NotFound
