import React from "react"
import { Outlet, useParams } from "react-router-dom"
import { Sidebar } from "../../components/admin/Sidebar"
import { Loader } from "../../components/Loader"
import { PRIVATE_PARAM } from "../../constants/privateParam"
import NotFound from "../NotFound"

const AdminRoot = () => {
    const { secret } = useParams<{ secret: string }>()

    if (secret !== PRIVATE_PARAM) {
        return (
            <NotFound />
        )
    }

    return (
        <React.Suspense fallback={<Loader />}>
            <div className="flex h-screen">
                <Sidebar />

                <div className="flex-1 overflow-auto">
                    <Outlet />
                </div>
            </div>
        </React.Suspense>
    )
}

export default AdminRoot