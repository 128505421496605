import { isDate } from "date-fns"
import { FormEvent, useCallback, useEffect, useRef, useState } from "react"
import { ValidatorFn } from "./validators"

export type ValidationSchema<T> = {
    [prop in keyof T]?: ValidatorFn<T>[] | ValidationSchema<T[prop]>
}

function getByPath(path: string[], obj: any = {}): any {
    if (path.length === 1) {
        return obj[path[0]]
    } else {
        const p = path.slice(1)
        return getByPath(p, obj[path[0]])
    }
}

function setByPathImmutable(path: string[], obj: any = {}, value: any): any {
    if (path.length === 1) {
        return {
            ...obj,
            [path[0]]: value
        }
    } else {
        return {
            ...obj,
            [path[0]]: setByPathImmutable(path.slice(1), obj[path[0]], value)
        }
    }
}

function flattenObject(obj: any = {}, base: string = '') {
    let res = {}

    Object.entries(obj).forEach(([key, value]) => {
        const newKey = base ? `${base}.${key}` : key

        if (!Array.isArray(value) && !isDate(value) && value !== null && typeof value === 'object') {
            res = {
                ...res,
                ...flattenObject(obj[key], newKey)
            }
        } else {
            res = {
                ...res,
                [newKey]: value
            }
        }
    })

    return res
}

export const useForm = <T>(schema: ValidationSchema<T>, onSubmit: (data: T) => void, initData?: Partial<T>) => {
    const [data, setData] = useState<Partial<T> | undefined>(initData)
    const [errors, setErrors] = useState<Partial<{ [prop in keyof T]: any }>>({})
    const dataRef = useRef<Partial<T>>() // workaround for accessing state immediately

    useEffect(() => {
        setData(initData)
    }, [initData])

    const validateField = useCallback((name: string, value?: any, formData: (Partial<T> | undefined) = data) => {
        const path = name.split('.')
        const validators = getByPath(path, schema) as ValidatorFn[]
        if (validators) {
            const failedValidator = validators.map(v => v(value, formData))
                .find(v => !v.isValid)


            setErrors(cur => {
                return setByPathImmutable(path, cur, failedValidator?.message)
            })

            return failedValidator ? failedValidator.isValid : true
        }
    }, [schema, data])

    const handleSubmit = useCallback((e: FormEvent) => {
        e.preventDefault()
        let isValid = true

        const flattenData = flattenObject(data)
        Object.keys(flattenObject(schema)).forEach((key: any) => {
            const value = flattenData && (flattenData as any)[key]

            const res = validateField(key, value as any)

            if (!res) isValid = false
        })

        if (isValid) {
            onSubmit(data as T)
        }
    }, [schema, data, validateField, onSubmit])

    const handleChange = useCallback((e: any) => {
        let { name, value } = e.target as { name: any, value: any }

        if (e.target.type === 'checkbox') {
            value = e.target.checked
        }

        setData(cur => {
            return setByPathImmutable(name.split('.'), cur, value)
        })

        validateField(name, value)
    }, [validateField])

    const handleBlur = useCallback((e: any) => {
        const { name } = e.target as { name: string }
        const value = data && (data as any)[name]

        validateField(name, value)
    }, [validateField, data])

    const setValues = useCallback((values: Partial<T>, skipValidation: boolean = false) => {
        const flatten = flattenObject(values)

        setData(cur => {
            const res = Object.entries(flatten).reduce((acc, [key, value]) => {
                return setByPathImmutable(key.split('.'), acc, value)
            }, cur)

            dataRef.current = res

            return res
        })

        if (!skipValidation) {
            Object.entries(flatten).forEach(([key, value]) => {
                validateField(key, value, dataRef.current)
            })
        } else {
            setErrors({})
        }
    }, [validateField])

    const reset = useCallback(() => {
        setData(initData)
        setErrors({})
    }, [initData])

    return {
        data,
        errors,
        handleSubmit,
        handleChange,
        handleBlur,
        setValues,
        reset
    }
}