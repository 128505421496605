import { Tooltip } from "@mui/material";
import { PropsWithChildren } from "react";
import { FeatureEnabledResult } from "../../queries/useFeature";

type Props = PropsWithChildren & {
    enabled?: FeatureEnabledResult
    className?: string
}

export default function Upselled({
    enabled,
    children,
    className
}: Props) {
    if (enabled === 'hidden' || enabled === 'loading') {
        return null
    }

    if (enabled === 'disabled') {
        return (
            <Tooltip
                title="This feature is disabled. In order to enable it, please contact administrators"
                enterTouchDelay={0}
            >
                <div className={className}>
                    <div className="opacity-40 pointer-events-none flex">
                        {children}
                    </div>
                </div>
            </Tooltip>
        )
    }

    if (enabled === 'enabled') {
        return (
            <div className={className}>
                {children}
            </div>
        )
    }

    return null
}