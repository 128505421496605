import { useParams } from "react-router-dom"
import { ActionButton } from '../components/common'
import { useCallback } from "react"
import { useSnackbar } from "notistack"
import { LegendParams } from "../types/common"
import useFeature from "../queries/useFeature"

export const MorePage = () => {
    const { legendId, sessionId } = useParams<LegendParams>()
    const selectionEnabled = useFeature('selection')
    const { enqueueSnackbar } = useSnackbar()

    const handleCopy = useCallback(() => {
        navigator.clipboard.writeText(
            `${window.location.origin}/l/${legendId}/s/${sessionId}`
        )
            .then(() => {
                enqueueSnackbar('Personal link is copied', {
                    variant: 'success'
                })
            })
    }, [enqueueSnackbar, legendId, sessionId])

    return (
        <div className="flex w-full items-center px-4 flex-col md:gap-12 gap-8 max-w-4xl mx-auto text-center">
            <div className="md:py-8 flex flex-col gap-10">
                <h4 className="text-4xl">
                    Information
                </h4>

                <ul className="flex flex-col gap-4">
                    <li>
                        <a href="https://engagementenhancers.com/pages/about#faq" target="_blank" rel="noreferrer" className="text-[#EF7E65] text-3xl underline">
                            FAQ
                        </a>
                    </li>

                    <li>
                        <a href="https://engagementenhancers.com/pages/together" target="_blank" rel="noreferrer" className="text-[#EF7E65] text-3xl underline">
                            We are looking for ideas and assistance to grow the brand
                        </a>
                    </li>
                </ul>
            </div>

            <hr className="border-b w-full" />

            {selectionEnabled && (
                <>
                    <div className="flex flex-col gap-4 items-center">
                        <span className="text-xl">
                            Your personal session is unique to this device and browser session. If you would like to move it elsewhere then click below to copy your personal link to the clipboard
                        </span>

                        <ActionButton
                            onClick={handleCopy}
                            className="bg-[#EF7E65] w-fit">
                            Copy
                        </ActionButton>
                    </div>

                    <hr className="border-b w-full" />
                </>
            )}

            <div className="flex flex-col items-center">
                <span className="text-xl">
                    Let others scan to give them the legend
                </span>

                <img src="/assets/legend.png" alt="legend" className="w-48" />
            </div>
        </div>
    )
}