import React from "react";
import { ActionFunction } from "react-router-dom";
import { API_BASE } from "../constants/api";

export const action: ActionFunction = async ({ params, request }) => {
  const formData = await request.formData()
  const obj = Object.fromEntries(formData) as any

  if (request.method === 'POST') {
    return await fetch(`${API_BASE}/roster/${params.hash!}/${params.sessionId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(obj)
    })
  }

  if (request.method === 'DELETE') {
    return await fetch(`${API_BASE}/roster/${params.hash!}/${params.sessionId!}`, {
      method: 'DELETE',
    })
  }

  return null
}

export const LazyComponent = React.lazy(() => import('../pages/roster'))