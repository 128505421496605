import React, { ReactNode } from "react"
import c from 'classnames'

type Props = {
    value?: any
    name?: string
    onChange?: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
    label?: string | null | ReactNode
    placeholder?: string
    placeholderIcon?: ReactNode,
    onBlur?: (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => void
    disabled?: boolean
    error?: any
    className?: string
    multiline?: boolean
    inputType?: string
}

export const inputClasses = 'px-4 py-3 text-gray-900 text-xs w-full bg-transparent outline-none'

const TextInput = ({
    value = '',
    onChange,
    label,
    name,
    inputType,
    placeholder,
    placeholderIcon,
    className,
    multiline,
    onBlur,
    disabled,
    error
}: Props) => {
    let element = null

    if (multiline) {
        element = (
            <textarea
                value={value}
                name={name}
                onChange={e => onChange && onChange(e)}
                placeholder={placeholder}
                onBlur={onBlur}
                disabled={disabled}
                rows={4}
                className={inputClasses}
            />
        )
    } else {
        element = (
            <input
                value={value}
                name={name}
                type={inputType}
                onChange={e => onChange && onChange(e)}
                placeholder={placeholder}
                onBlur={onBlur}
                disabled={disabled}
                className={inputClasses}
            />
        )
    }

    return (
        <label className={c("flex flex-col gap-2", disabled && 'opacity-70', className)}>
            {label && (
                <div className="text-gray-600 text-sm font-medium">
                    {label}
                </div>
            )}

            <div className={
                c(
                    "border rounded-md bg-gray-100 flex items-center",
                    error ? 'border-red-400' : 'border-gray-200'
                )}>
                {placeholderIcon && (
                    <div className="pl-4">
                        {placeholderIcon}
                    </div>
                )}

                {element}
            </div>

            {error && (
                <div className="text-sm text-red-400">
                    {error}
                </div>
            )}
        </label>
    )
}

export default TextInput