import { Link, NavLink, useParams } from "react-router-dom"
import cx from 'classnames'
import { Logo } from "../common"
import Upselled from "../UpsellDialog/Upselled"
import { getFeature } from "../../queries/useFeature"
import { Feature } from "../../types/Manifest"
import { useManifest } from "../../queries/useManifest"

const links: {
    title: string,
    href: string
    feature?: Feature
}[] = [{
    title: 'Stats',
    href: 'stats',
    feature: 'selection'
}, {
    title: 'Announcements',
    href: 'news',
    feature: 'announcements'
}, {
    title: 'Itinerary',
    href: 'itinerary',
    feature: 'schedule'
}, {
    title: 'Engagement',
    href: 'engagement'
}, {
    title: 'Settings',
    href: 'settings'
}]

export const Sidebar = () => {
    const { legendId } = useParams<{ legendId: string }>()
    const [manifest] = useManifest()

    return (
        <div className="flex-0 flex flex-col w-52 border-r shadow-lg">
            <div className="p-8 flex flex-col gap-4">
                <Link to={''}>
                    <Logo />
                </Link>

                <div className="text-primary-500 text w-fit py-1 italic">
                    Admin
                </div>
            </div>

            <hr />

            <div className="flex flex-col p-4">
                {links.map(l => (
                    <Upselled
                        enabled={l.feature ? getFeature(manifest!, l.feature) : 'enabled'}
                        className="flex"
                    >
                        <NavLink
                            key={l.title}
                            to={`l/${legendId}/${l.href}`}
                            className={({ isActive }) => cx(
                                'px-3 py-2 hover:bg-gray-200 transition w-full',
                                isActive && 'bg-primary-500/[0.1] text-primary-500 border-r border-primary-500 w-full'
                            )}
                        >
                            {l.title}
                        </NavLink>
                    </Upselled>
                ))}
            </div>
        </div>
    )
}