import { PropsWithChildren, useCallback, useEffect, useRef, useState } from "react"
import { BiChevronLeft, BiChevronRight } from "react-icons/bi"
import cx from 'classnames'
import styled from "@emotion/styled"

type Props = PropsWithChildren

const Wrapper = styled.div`
    &::-webkit-scrollbar {
        display: none;
    }
`

const btnClass = 'w-8 h-8 flex items-center justify-center rounded-full bg-[#FFF9EE]'

export default function Carousel({
    children
}: Props) {
    const [activeIndex, setActiveIndex] = useState(0)
    const wrapperRef = useRef<HTMLDivElement>(null)
    const [prevVisible, setPrevVisible] = useState(false)
    const [nextVisible, setNextVisible] = useState(false)

    const renderArrows = useCallback(() => {
        const target = wrapperRef.current
        if (!target) return

        setPrevVisible(target.scrollLeft > 0)

        setNextVisible(Math.ceil(target.scrollLeft + target.clientWidth) < target.scrollWidth)
    }, [])

    const handleScroll = useCallback(() => {
        renderArrows()

        const target = wrapperRef.current
        if (!target) return

        const children = target.children
        const width = children[0].clientWidth

        const scrolled = target.scrollLeft
        setActiveIndex(Math.floor(scrolled / width))
    }, [renderArrows])

    const handleNavigate = useCallback((direction: 'left' | 'right') => {
        if (!wrapperRef.current) return

        const children = wrapperRef.current.children
        const width = children[0].clientWidth

        wrapperRef.current.scrollBy({
            left: direction === 'right' ? width : -width,
            behavior: 'smooth'
        })
    }, [])

    const handlePrev = useCallback(() => {
        handleNavigate('left')
    }, [handleNavigate])

    const handleNext = useCallback(() => {
        handleNavigate('right')
    }, [handleNavigate])

    useEffect(() => {
        if (!wrapperRef.current) return

        renderArrows()

        const obs = new ResizeObserver(() => {
            renderArrows()
        })

        obs.observe(document.body)

        return () => obs.disconnect()
    }, [renderArrows])

    return (
        <div className="w-full relative">
            <Wrapper className="flex gap-4 overflow-x-auto snap-mandatory snap-x" onScroll={handleScroll} ref={wrapperRef}>
                {children}
            </Wrapper>

            <div className="flex justify-between items-center p-8 max-w-md mx-auto">
                <button
                    className={cx(btnClass, prevVisible ? 'opacity-80' : 'opacity-0')}
                    onClick={handlePrev}
                    aria-label="Navigate Back"
                >
                    <BiChevronLeft className="text-primary-500" size={25} />
                </button>

                <div className="w-24 rounded-xl h-2 relative bg-gray-200 overflow-hidden">
                    <span className="absolute left-0 top-0 h-full bg-primary-500 transition" style={{ width: `${100 / 4 * (activeIndex + 1)}%` }} />
                </div>

                <button
                    className={cx(btnClass, nextVisible ? 'opacity-80' : 'opacity-0')}
                    onClick={handleNext}
                    aria-label="Navigate Forward"
                >
                    <BiChevronRight className="text-primary-500" size={25} />
                </button>
            </div>
        </div>
    )
}