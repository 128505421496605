import { PropsWithChildren } from "react"
import { Loader } from "./Loader"

type Props = PropsWithChildren & {
    loading?: boolean
}

export const GlobalLoader = ({
    children,
    loading
}: Props) => {
    if (loading) return <Loader />

    return (
        <>
            {children}
        </>
    )
}