import { useParams } from "react-router-dom"
import { RangeType } from "../../components/admin/settings/ScheduleSection"
import { API_BASE } from "../../constants/api"
import { LegendParams } from "../../types/common"
import { useCommonQuery } from "../useCommonQuery"

function fromServer(dto?: any): any {
    if (!dto) return undefined

    return {
        ...dto,
        schedule: dto.schedule?.map((s: any) => ({
            ...s,
            date: new Date(s.date),
            time: {
                start: new Date(s.time?.start),
                end: new Date(s.time?.end)
            }
        }))
    }
}

export const useLegend = () => {
    const { legendId } = useParams<LegendParams>()

    return useCommonQuery<{ attendees: number, schedule: RangeType[] }>(
        ['legend-settings', legendId],
        async () => {
            if (!legendId) return

            const res = await fetch(`${API_BASE}/settings/${legendId}`)
            if (!res.ok) throw Error('Invalid response')

            const json = await res.json()

            return fromServer(json)
        }
    )
}