const keyBase = 'legend-help-'

const getKey = (legendId: string) => `${keyBase}-${legendId}`

export const OnboardingState = {
    get(legendId: string) {
        const key = getKey(legendId)
        return localStorage.getItem(key)
    },
    markAsRead(legendId: string) {
        const key = getKey(legendId)

        localStorage.setItem(key, '1')
    }
}