import { Dialog } from "@mui/material"
import { useCallback, useEffect, useState } from "react";
import { formatDate } from "../../../utils/date";
import { useForm, ValidationSchema } from "../../../utils/form";
import { ActionButton } from "../../common";
import TimeSpanPicker, { TimeRange } from "../../TimeSpanPicker";
import * as Validators from '../../../utils/validators'

type Props = {
    opened?: boolean
    onClose?: () => void
    date?: Date
    onSet: (range: TimeRange, date?: Date) => void
    range?: TimeRange
}

type Form = TimeRange

const schema: ValidationSchema<Form> = {
    start: [Validators.required, Validators.timeRelationStart('end')],
    end: [Validators.required, Validators.timeRelationEnd('start')]
}

export default function TimeDialog({
    opened,
    onClose,
    onSet,
    range: rangeProp,
    date
}: Props) {
    const [range, setRange] = useState<TimeRange>(rangeProp ?? {
        start: new Date(),
        end: new Date()
    })

    useEffect(() => {
        if (rangeProp) {
            setRange(rangeProp)
        }
    }, [rangeProp])

    const handleApply = useCallback((formData: TimeRange) => {
        onSet(formData, date)
    }, [onSet, date])

    const {
        data,
        errors,
        handleSubmit,
        setValues
    } = useForm<Partial<Form>>(schema, handleApply, range)

    const handleChange = useCallback((e: TimeRange) => {
        setValues(e)
    }, [setValues])

    return (
        <Dialog
            open={!!opened}
            onClose={onClose}
        >
            <div className="flex flex-col p-4 gap-4" onSubmit={handleSubmit}>
                <h2>{formatDate(date)}</h2>

                <TimeSpanPicker value={data} onChange={handleChange} errors={errors} />

                <ActionButton className="ml-auto" type="button" onClick={handleSubmit}>Apply</ActionButton>
            </div>
        </Dialog>
    )
}