import { ActionFunction, LoaderFunction } from "react-router-dom";
import { API_BASE } from "../constants/api";

export const loader: LoaderFunction = async ({ params }) => {
    const res = await fetch(`${API_BASE}/l/${params.hash!}/rating/${params.sessionId}`)

    return await res.json()
}

export const action: ActionFunction = async ({ params, request }) => {
    const formData = await request.formData()
    const obj = Object.fromEntries(formData) as any

    if (request.method === 'POST') {
        return await fetch(`${API_BASE}/l/${params.hash!}/rating/${params.sessionId}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(obj)
        })
    }
}