import { eachDayOfInterval } from "date-fns";
import { useCallback, useState } from "react";
import { ActionButton } from "../../common";
import DateDialog from "./DateDialog";
import { Range } from "../../DateSpanPicker";
import { BsFillCalendarCheckFill } from 'react-icons/bs'
import TimeDialog from "./TimeDialog";
import { TimeRange } from "../../TimeSpanPicker";
import { IoIosTimer } from 'react-icons/io'
import { formatDate, formatTime } from "../../../utils/date";
import { AiOutlineDelete } from 'react-icons/ai'
import { arrayPr } from "../../../utils/placeholder";

export type RangeType = {
    time?: TimeRange
    date: Date
}

type Props = {
    schedule?: RangeType[]
    setValues: (values: any) => void
    errors?: any
}

export default function ScheduleSection({
    schedule = arrayPr,
    setValues,
    errors
}: Props) {
    const [openedAdd, setOpenedAdd] = useState(false)
    const [timeModalOpened, setTimeModalOpened] = useState(false)

    const [edited, setEdited] = useState<RangeType>()

    const handleAdd = useCallback((range: Range) => {
        const days = eachDayOfInterval({
            start: range.startDate,
            end: range.endDate
        })

        setValues({
            schedule: [...schedule, ...days.map(d => ({ date: d }))]
        })
        setOpenedAdd(false)
    }, [schedule, setValues])

    const handleSetTime = useCallback((range: TimeRange) => {
        setTimeModalOpened(false)

        const selected = schedule.find(d => d === edited)

        if (!selected) return

        setValues({
            schedule: schedule.map(c => {
                if (c === edited) {
                    return {
                        ...c,
                        time: range
                    }
                }

                return c
            })
        })
    }, [schedule, edited, setValues])

    const handleDelete = useCallback((r: RangeType) => {
        setValues({
            schedule: schedule.filter(c => c !== r)
        })
    }, [schedule, setValues])

    return (
        <div className="flex flex-col gap-2 pt-4">
            <h2 className="text-md font-regular mb-8">Schedule</h2>

            <ActionButton type="button" onClick={() => setOpenedAdd(true)}>Add</ActionButton>


            {errors && (
                <div className="text-sm text-red-400">
                    {errors}
                </div>
            )}

            <div className="flex flex-col divide-y pt-4">
                {schedule.map((d, i) => (
                    <div className="flex gap-3 items-center divide-b py-1">
                        <BsFillCalendarCheckFill className="text-primary-500 mr-2" />
                        <div className="w-48 shrink-0 font-regular">
                            {formatDate(d.date)}
                        </div>

                        {d.time && (
                            <div>
                                {formatTime(d.time.start)} - {formatTime(d.time.end)}
                            </div>
                        )}

                        <div className="flex gap-3 ml-auto">
                            <button
                                type="button"
                                className="text-primary-500" onClick={() => {
                                    setEdited(d)
                                    setTimeModalOpened(true)
                                }}>
                                <IoIosTimer />
                            </button>

                            <button
                                type="button"
                                onClick={() => handleDelete(d)}
                                className="text-primary-500"
                            >
                                <AiOutlineDelete />
                            </button>
                        </div>
                    </div>
                ))}
            </div>

            <DateDialog ranges={schedule} opened={openedAdd} onClose={() => setOpenedAdd(false)} onAdd={handleAdd} />
            <TimeDialog
                date={edited?.date}
                opened={timeModalOpened}
                onClose={() => setTimeModalOpened(false)}
                onSet={handleSetTime}
                range={edited?.time}
            />
        </div>
    )
}