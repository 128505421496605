import { Manifest } from "../../types/Manifest"
import { HTTPError } from "../errors"

export async function fetchManifest(hash: string) {
    const url = `${process.env.REACT_APP_CDN_BASE}/${hash}/manifest.json?cache=3`
    const res = await fetch(url)

    if (!res.ok) throw new HTTPError()
    const payload = await res.json() as Manifest

    if (!payload.isActive) throw Error('Event is not active')

    return payload
}