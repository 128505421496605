import { Outlet, useParams } from "react-router-dom"
import { Loader } from "../components/Loader"
import { Suspense, useCallback, useState } from "react"
import PublicHeader from "../components/Header/public"
import { OnboardingState } from "../utils/onboarding"
import ProductOnboardingDialog from "../components/onboarding/ProductOnboardingDialog"

const LegendRoot = () => {
    const { legendId } = useParams<{ legendId: string }>()
    const [showOnboarding, setShowOnboarding] = useState(!OnboardingState.get(legendId!))

    const onOnboardingAccept = useCallback(() => {
        OnboardingState.markAsRead(legendId!)
        setShowOnboarding(false)
    }, [legendId])

    return (
        <>
            <PublicHeader />
            <Suspense fallback={<Loader />}>
                <Outlet />
            </Suspense>

            {/* <AppOnboardingDialog
                open={showOnboarding}
                onAccept={onOnboardingAccept}
            /> */}

            <ProductOnboardingDialog
                open={showOnboarding}
                onClose={onOnboardingAccept}
            />
        </>
    )
}

export default LegendRoot
