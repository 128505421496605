import { Feature, Manifest } from "../types/Manifest";
import { useManifest } from "./useManifest";

export type FeatureEnabledResult = 'enabled' | 'disabled' | 'hidden' | 'loading'

export function getFeature(manifest: Manifest, name: Feature): FeatureEnabledResult {
    const config = manifest?.featureFlags?.find(f => f.feature === name)
    if (!config || (config.isAvailable && config.isVisible)) return 'enabled' // by default feature is visible and enable if it's not specified in config

    if (!config.isVisible) return 'hidden'
    // disabled - shown but behind a paywall or some banner
    else return 'disabled'
}

export default function useFeature(name: Feature): FeatureEnabledResult {
    const [manifest, loading] = useManifest()

    if (loading) return 'loading'

    return getFeature(manifest!, name)
}
