import { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useFetcher } from "react-router-dom";
import LegendExample from "../components/LegendExample";
import { LegendGrid } from "../components/LegendGrid";
import LegendPickerDialog from "../components/LegendPickerDialog";
import SelectedArea from "../components/SelectedArea";
import { useSelections } from "../queries/useSelections";
import { Combo } from "../types/Combo";
import { Sticker } from "../types/Sticker";
import { arrayPr } from "../utils/placeholder";
import { useAddSelection } from "../queries/useAddSelection";
import { useDeleteSelection } from "../queries/useDeleteSelection"
import { useStickers } from "../queries/useStickers";
import { LegendGridPlaceholder } from "../components/LegendGrid/placeholder";
import { ActionButton } from "../components/common";
import useFeature from "../queries/useFeature";
import Upselled from "../components/UpsellDialog/Upselled";

export default function LegendPage() {
    const selectionFetcher = useFetcher()
    const selectionEnabled = useFeature('selection')
    const announcementsEnabled = useFeature('announcements')
    
    const [stickers = [], loadingStickers] = useStickers()
    const [selections = arrayPr, loadingSelections] = useSelections()

    const [attrDialogOpened, setAttrDialogOpened] = useState(false)
    const [picks, setPicks] = useState<Combo[]>([])
    const [addSelection, isAddingSelection] = useAddSelection()
    const [deleteSelection, isDeletingSelection] = useDeleteSelection()

    const subjectStickers = useMemo(() => {
        return stickers.filter(s => s.type === 'subject')
            .filter(s => !picks.some(p => p.subject?.name === s.name))
    }, [stickers, picks])

    const attrStickers = useMemo(() => {
        return stickers.filter(s => s.type === 'attr')
    }, [stickers])

    const [selectedSubject, setSelectedSubject] = useState<Sticker>()

    useEffect(() => {
        setPicks(selections.map((r: any) => {
            const subject = stickers.find(s => s.name === r.subjectName)!
            const attr = stickers.find(s => s.name === r.attrName)

            return {
                subject,
                attr
            }
        }))
    }, [stickers, selections])

    const handleAddSelection = useCallback((combo: Combo) => {
        addSelection({
            subjectName: combo.subject?.name!,
            attrName: combo.attr?.name
        })

        setPicks(cur => {
            return [...cur, combo]
        })
    }, [addSelection])

    const handleRemove = useCallback(async (subjName: string) => {
        deleteSelection({
            subjName
        })

        setPicks(cur => cur.filter(c => c.subject.name !== subjName))
    }, [deleteSelection])

    const handleSelectSubject = useCallback((s: Sticker) => {
        if (!attrStickers.length) {
            handleAddSelection({
                subject: s
            })
        } else {
            setSelectedSubject(s)
            setAttrDialogOpened(true)
        }
    }, [attrStickers, handleAddSelection])

    const handleSelectAttr = useCallback(async (attr?: Sticker) => {
        if (!selectedSubject) return

        const combo = {
            subject: selectedSubject,
            attr
        }

        handleAddSelection(combo)

        setAttrDialogOpened(false)
    }, [selectedSubject, handleAddSelection])

    const scrollToExample = useCallback(() => {
        const example = document.getElementById('example')
        example?.scrollIntoView({
            behavior: 'smooth'
        })
    }, [])

    return (
        <>
            <div className="bg-white md:p-10 p-2 flex flex-col pb-32">
                {loadingStickers ? (
                    <LegendGridPlaceholder title="Select your interests" />
                ) : (
                    <LegendGrid
                        title="Select your interests"
                        stickers={subjectStickers}
                        clickable={!loadingSelections}
                        onClick={handleSelectSubject}
                        selectionEnabled={selectionEnabled}
                    />
                )}

                <LegendPickerDialog
                    stickers={attrStickers}
                    opened={attrDialogOpened}
                    onClose={() => setAttrDialogOpened(false)}
                    onSelect={handleSelectAttr}
                    selectionEnabled={selectionEnabled}
                />

                <div id="example" className="flex flex-col w-full items-center py-12">
                    <h2 className="text-2xl font-bold mb-8">Examples</h2>
                    <LegendExample subjects={subjectStickers} attrs={attrStickers} />
                </div>
            </div>

            <div className="fixed w-fit flex gap-2 md:bottom-32 bottom-28 right-2 md:right-4 md:justify-end justify-center w-full">
                <ActionButton
                    onClick={scrollToExample}>
                    Example
                </ActionButton>
                {!!picks.length && (
                    <Upselled enabled={announcementsEnabled}>
                        <Link to="./announcements">
                            <ActionButton>
                                Announcements
                            </ActionButton>
                        </Link>
                    </Upselled>
                )}
            </div>

            {selectionEnabled === 'enabled' && (
                <SelectedArea
                    selections={picks}
                    onRemove={handleRemove}
                    fetcher={selectionFetcher}
                    loading={loadingSelections}
                    updating={isAddingSelection || isDeletingSelection}
                />
            )}
        </>
    )
}