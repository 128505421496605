import { useParams } from "react-router-dom"
import { API_BASE } from "../constants/api"
import { LegendParams } from "../types/common"
import { useCommonFetchMutation } from "./useCommonMutation"

export const useDeleteSelection = () => {
    const { legendId, sessionId } = useParams<LegendParams>()

    return useCommonFetchMutation<{ subjName: string }>(
        async ({ subjName }) => {
            const res = await fetch(`${API_BASE}/selections/${legendId}/${sessionId}/${encodeURIComponent(subjName)}`, {
                method: 'DELETE'
            })

            if (!res.ok) throw await res.json()
        },
        Boolean(legendId && sessionId)
    )
}