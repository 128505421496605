import { LegendItemPlaceholder } from "../LegendItem/placeholder";

type Props = {
    title: string
}

export const LegendGridPlaceholder = ({
    title
}: Props) => (
    <div className="md:py-8 py-2">
        <h2 className="md:text-2xl text-xl font-bold md:mb-8 mb-4 px-2">{title}</h2>
        <div className="flex gap-4 grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1">
            {Array(12).fill('').map((_, i) => (
                <LegendItemPlaceholder key={i} />
            ))}
        </div>
    </div>
)