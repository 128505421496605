import { Dialog } from "@mui/material"
import { useCallback, useEffect, useState } from "react"
import { ActionButton } from "../common"
import cx from 'classnames'
import TextInput from "../TextInput"
import { LoaderOverlay } from "../LoaderOverlay"
import { useRating } from "../../queries/useEventRating"
import { useSetRating } from "../../queries/useSetEventRating"

type Props = {
    opened?: boolean
    onClose?: () => void
}

const feels = [{
    emoji: '😭',
    score: -2,
    text: 'Awful'
}, {
    emoji: '😕',
    score: -1,
    text: 'Bad'
}, {
    emoji: '😐',
    score: 0,
    text: 'Okay'
}, {
    emoji: '😊',
    score: 1,
    text: 'Good'
}, {
    emoji: '😀',
    score: 2,
    text: 'Amazing'
}]

export const RateEventDialog = ({
    opened,
    onClose,
}: Props) => {
    const [rating, setRating] = useState<number>(0)
    const [message, setMessage] = useState<string>()
    const [data, loadingData] = useRating()
    const [rate, settingRating] = useSetRating()

    useEffect(() => {
        setRating(+data?.rating)
    }, [data])

    const handleSubmit = useCallback(() => {
        rate({
            rating,
            message
        }, {
            onSuccess: () => onClose && onClose()
        })
    }, [rating, message, onClose, rate])
    
    const handleMessageChange = useCallback((e?: any) => {
        setMessage(e.target.value)
    }, [])

    return (
        <Dialog
            open={!!opened}
            onClose={onClose}
        >
            <div className="md:px-8 py-4 px-4 relative flex flex-col">
                <h2 className="md:text-xl text-base md:mb-8 mb-4 px-2 text-center">
                    Liked event?
                </h2>

                <div className="flex gap-2 md:text-6xl text-5xl overflow-auto">
                    {feels.map((f, i) => (
                        <button
                            key={i}
                            type="button"
                            className={cx("hover:bg-[#f1f1f1] md:p-2 p-1 rounded transition md:text-6xl text-3xl", rating === f.score && 'bg-gray-200')}
                            onClick={() => setRating(f.score)}
                        >
                            {f.emoji}
                        </button>
                    ))}
                </div>
                <input type="hidden" name="rating" value={rating} />

                <TextInput
                    name="message"
                    label="Feedback"
                    multiline
                    className="pt-4"
                    value={message}
                    onChange={handleMessageChange}
                />

                <div className="flex justify-center pt-8">
                    <ActionButton onClick={handleSubmit}>Submit</ActionButton>
                </div>

                {(loadingData || settingRating) && <LoaderOverlay />}
            </div>
        </Dialog>
    )
}