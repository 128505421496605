import { useParams } from "react-router-dom";
import { LegendParams } from "../types/common";
import { Sticker } from "../types/Sticker";
import { fetchStickers } from "../utils/http/fetchStickers";
import { useCommonQuery } from "./useCommonQuery";

export const useStickers = () => {
    const { legendId } = useParams<LegendParams>()

    return useCommonQuery<Sticker[]>(
        ['legend', legendId],
        async () => {
            if (!legendId) return []

            return await fetchStickers(legendId)
        }
    )
}