import { ActionButton, Logo } from "../common"
import { Link, NavLink, useParams } from "react-router-dom"
import { RateEventDialog } from '../dialogs/RateEventDialog'
import { useCallback, useState } from 'react'
import { LegendParams } from "../../types/common"
import useFeature from "../../queries/useFeature"
import Upselled from "../UpsellDialog/Upselled"

const PublicHeader = () => {
    const rosterEnabled = useFeature('roster')
    const announcementsEnabled = useFeature('announcements')
    const scheduleEnabled = useFeature('schedule')
    const ratingEnabled = useFeature('rating')

    const { legendId, sessionId } = useParams<LegendParams>()

    const baseHref = `/l/${legendId}/s/${sessionId}`

    const [rateDialogOpened, setRateDialogOpened] = useState(false)

    const handleToggleRateDialog = useCallback(() => setRateDialogOpened(cur => !cur), [])

    return (
        <>
            <nav className="bg-white sticky top-0 border-gray-200 px-2 sm:px-4 py-2.5 rounded bg-white z-[100] shadow-sm mb-8">
                <div className="container flex flex-wrap items-center justify-between mx-auto py-2">
                    <Link className="h-8 pl-3 md:pl-0 flex" to={baseHref}>
                        <Logo />
                    </Link>
                    <div className="flex items-center gap-6 px-4 md:justify-center overflow-auto md:overflow-visible">
                        <Upselled enabled={rosterEnabled}>
                            <NavLink to={`${baseHref}/roster`}
                                className={({ isActive }) => `h-full flex items-center ${isActive && 'font-bold'}`}>
                                Roster
                            </NavLink>
                        </Upselled>

                        <NavLink
                            to={baseHref}
                            end
                            className={({ isActive }) => `h-full flex items-center ${isActive && 'font-bold'}`}>
                            Legend
                        </NavLink>

                        <Upselled enabled={announcementsEnabled}>
                            <NavLink to={`${baseHref}/announcements`}
                                className={({ isActive }) => `h-full flex items-center ${isActive && 'font-bold'}`}>
                                Announcements
                            </NavLink>
                        </Upselled>

                        <Upselled enabled={scheduleEnabled}>
                            <NavLink to={`${baseHref}/itinerary`}
                                className={({ isActive }) => `h-full flex items-center ${isActive && 'font-bold'}`}>
                                Schedule
                            </NavLink>
                        </Upselled>

                        <NavLink to={`${baseHref}/more`}
                            className={({ isActive }) => `h-full flex items-center ${isActive && 'font-bold'}`}>
                            More
                        </NavLink>

                        <Upselled enabled={ratingEnabled}>
                            <ActionButton onClick={handleToggleRateDialog}>
                                Liked event?
                            </ActionButton>
                        </Upselled>
                    </div>
                </div>
            </nav>

            <RateEventDialog
                opened={rateDialogOpened}
                onClose={handleToggleRateDialog}
            />
        </>
    )
}

export default PublicHeader
