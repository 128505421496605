import { PropsWithChildren } from "react"

type Props = PropsWithChildren

const PageContainer = ({
    children
}: Props) => {
    return (
        <div className="bg-white md:p-14 p-4 flex flex-col h-full">
            {children}
        </div>
    )
}

export default PageContainer
