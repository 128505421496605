import { useCallback } from 'react'
import { useSnackbar } from "notistack"

export const useErrorSnackbar = () => {
	const { enqueueSnackbar } = useSnackbar()

	return useCallback((err: any) => {
		console.error(err)
		enqueueSnackbar(err.toString(), {
			variant: 'error'
		})
	}, [enqueueSnackbar])
}