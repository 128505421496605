import { useParams } from "react-router-dom"
import { LegendParams } from "../types/common"
import { Manifest } from "../types/Manifest"
import { useCommonQuery } from "./useCommonQuery"
import { fetchManifest } from "../utils/http/fetchManifest"

export const useManifest = () => {
    const { legendId } = useParams<LegendParams>()

    return useCommonQuery<Manifest | undefined>(
        ['manifest', legendId],
        async () => {
            if (!legendId) return undefined

            return await fetchManifest(legendId)
        }
    )
}