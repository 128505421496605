import {
  createBrowserRouter,
  RouterProvider
} from 'react-router-dom'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { SnackbarProvider } from 'notistack';
import { QueryClient, QueryClientProvider } from 'react-query';
import NotFound from './pages/NotFound';
import * as Root from './routes/root'
import * as Legend from './routes/legend'
import * as Roster from './routes/roster'
import * as Announcements from './routes/announcements'
import * as More from './routes/more'
import AdminRoot from './pages/admin/root';
import * as Stats from './routes/admin/stats'
import * as AdminNewsList from './routes/admin/announcements/list'
import * as AdminNewsDetail from './routes/admin/announcements/detail'
import * as Rating from './routes/rating'
import * as Itinerary from './routes/itinerary/itinerary'
import * as ItineraryRating from './routes/itinerary/rating'
import * as AdminItinerary from './routes/admin/itinerary'
import EngagementPage from './pages/admin/engagement';
import SettingsPage from './pages/admin/settings';

const router = createBrowserRouter([{
  // public routes
  path: '/l/:legendId',
  id: 'legend',
  ...Root,
  children: [{
    path: 's/:sessionId',
    id: 'session',
    children: [{
      index: true,
      ...Legend,
    }, {
      path: 'roster',
      ...Roster,
      element: <Roster.LazyComponent />
    }, {
      path: 'announcements',
      ...Announcements,
      element: <Announcements.LazyComponent />
    }, {
      path: 'more',
      ...More
    }, {
      path: 'rating',
      id: 'rating',
      ...Rating,
    }, {
      path: 'itinerary',
      ...Itinerary,
      element: <Itinerary.LazyComponent />,
      children: [{
        path: 'rating',
        ...ItineraryRating
      }]
    }
    ]
  }],
}, {
  // admin routes
  path: '/admin/:secret',
  element: <AdminRoot />,
  children: [{
    path: 'l/:legendId',
    children: [{
      path: 'stats',
      ...Stats,
      element: <Stats.LazyComponent />
    }, {
      path: 'news',
      ...AdminNewsList,
      element: <AdminNewsList.LazyComponent />,
    }, {
      path: 'news/:subjName',
      ...AdminNewsDetail,
      element: <AdminNewsDetail.LazyComponent />
    }, {
      path: 'itinerary',
      ...AdminItinerary,
      element: <AdminItinerary.LazyComponent />
    }, {
      path: 'engagement',
      element: <EngagementPage />
    }, {
      path: 'settings',
      element: <SettingsPage />
    }]
  }]
}, {
  path: '*',
  element: <NotFound />
}])

const queryClient = new QueryClient()

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <SnackbarProvider>
        <QueryClientProvider client={queryClient}>
          <RouterProvider router={router} />
        </QueryClientProvider>
      </SnackbarProvider>
    </LocalizationProvider>
  );
}

export default App;
