import { useParams } from "react-router-dom"
import { API_BASE } from "../constants/api"
import { ScalarStats } from "../types/Stat"
import { useCommonQuery } from "./useCommonQuery"

export const useRatingStats = (
    selections?: string[]
) => {
    const { legendId } = useParams<{ legendId: string }>()

    return useCommonQuery<{
        engagement: {
            total: number
            stats: ScalarStats[]
        },
        rating: ScalarStats[]
    }>(
        ['rating-stats', legendId, selections],
        async () => {
            if (!legendId) return

            const query = new URLSearchParams()
            selections?.forEach(s => query.append('selections', s))

            const data = await fetch(`${API_BASE}/rating-stats/${legendId}?${query.toString()}`)

            return await data.json()
        },
    )
}