import { ActionFunction } from "react-router-dom";
import { API_BASE } from "../../constants/api";

export const action: ActionFunction = async ({ params, request }) => {
    const formData = await request.formData()
    const body = Object.fromEntries(formData) as any

    if (request.method === 'PUT') {
        return await fetch(`${API_BASE}/l/${params.hash}/s/${params.sessionId}/itinerary/${body.id}/rating`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        })
    }
}
