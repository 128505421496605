import { Skeleton } from "@mui/material";

export const LegendItemPlaceholder = () => (
    <div className="flex gap-2 font-sans items-start text-start p-2">
        <div className="h-full">
            <Skeleton
                variant="rectangular"
                width={96}
                height={96}
                className="aspect-square rounded-lg flex justify-center items-center p-4h-full shrink-0"
            />
        </div>

        <div className="flex flex-col md:gap-4 gap-1 py-0 px-3 flex-1">
            <Skeleton variant="text" height={35} className="w-full" width="50%" />
            <div className="flex flex-col">
                <Skeleton variant="text" />
                <Skeleton variant="text" width="40%" />
            </div>
        </div>
    </div>
)