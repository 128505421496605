import { useCallback } from "react";
import ScheduleSection, { RangeType } from "../../components/admin/settings/ScheduleSection";
import { ActionButton } from "../../components/common";
import { GlobalLoader } from "../../components/GlobalLoader";
import { LoaderOverlay } from "../../components/LoaderOverlay";
import PageContainer from "../../components/PageContainer";
import TextInput from "../../components/TextInput";
import { useLegend } from "../../queries/legend/useLegend";
import { useSetLegend } from "../../queries/legend/useSetLegend";
import { useForm, ValidationSchema } from "../../utils/form";
import * as Validators from '../../utils/validators'

type Form = {
    attendees: number
    schedule: RangeType[]
}

const schema = {
    attendees: [Validators.required, Validators.positiveNumber],
    schedule: [(val: RangeType[]) => {
        if (!val) {
            return {
                isValid: true
            }
        }

        if (val.some(v => !v.time || !v.time.start || !v.time.end)) {
            return {
                isValid: false,
                message: 'Time should be specified for all scheduled days'
            }
        }

        return {
            isValid: true
        }
    }]
} as ValidationSchema<Form>

export default function SettingsPage() {
    const [settings, loadingSettings] = useLegend()
    const [updateSettings, updating] = useSetLegend()

    const saveForm = useCallback((form: Partial<Form>) => {
        updateSettings({
            attendees: form.attendees!,
            schedule: form.schedule ?? []
        })
    }, [updateSettings])

    const {
        data,
        handleChange,
        errors,
        handleBlur,
        setValues,
        handleSubmit
    } = useForm<Partial<Form>>(schema, saveForm, settings)

    return (
        <GlobalLoader loading={loadingSettings}>
            <PageContainer>
                <form className="p-8 relative bg-white relative flex flex-col gap-6 overflow-hidden w-full max-w-xl mx-auto"
                    onSubmit={handleSubmit}>
                    <h2 className="text-lg font-bold">Settings</h2>
                    <TextInput
                        name="attendees"
                        inputType="number"
                        value={data?.attendees}
                        label="Attendees"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={errors.attendees}
                    />

                    <ScheduleSection schedule={data?.schedule} setValues={setValues} errors={errors.schedule} />

                    <ActionButton className="ml-auto mt-auto" type="submit">Save</ActionButton>

                    {updating && <LoaderOverlay />}
                </form>
            </PageContainer>
        </GlobalLoader>
    )
}