import { LoaderFunction, redirect } from "react-router-dom"
import LegendRoot from "../containers/LegendRoot"
import SESSION from "../session"

export const loader: LoaderFunction = async ({ params }) => {
    if (!params.sessionId) {
        const session = SESSION.createOrGenerate()

        if (session) {
            throw redirect(`./s/${session}`)
        }

        throw Error('Session is not generated')
    }

    return null
}

export const element = <LegendRoot />
