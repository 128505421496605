import React from "react";
import { ActionFunction } from "react-router-dom";
import { API_BASE } from "../../constants/api";

export const action: ActionFunction = async ({ params, request }) => {
    const formData = await request.formData()
    const body = Object.fromEntries(formData) as any

    if (request.method === 'POST') {
        if (!body.id) {
            return await fetch(`${API_BASE}/l/${params.hash}/itinerary`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            })
        } else {
            return await fetch(`${API_BASE}/l/${params.hash}/itinerary/${body.id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            })
        }
    }

    if (request.method === 'DELETE') {
        return await fetch(`${API_BASE}/l/${params.hash}/itinerary/${body.id}`, {
            method: 'DELETE'
        })
    }
}

export const LazyComponent = React.lazy(() => import('../../pages/admin/itinerary'))