import { format, isSameHour } from "date-fns";
import { useMemo, useRef } from "react";
import { Chart, GoogleDataTableColumn } from "react-google-charts";
import { ScalarStats } from "../../types/Stat";

const feels = [{
    emoji: '😭',
    score: -2,
    text: 'Awful'
}, {
    emoji: '😕',
    score: -1,
    text: 'Bad'
}, {
    emoji: '😐',
    score: 0,
    text: 'Okay'
}, {
    emoji: '😊',
    score: 1,
    text: 'Good'
}, {
    emoji: '😀',
    score: 2,
    text: 'Amazing'
}]

export const options = {
    curveType: "function",
    legend: { position: "bottom" },
    backgroundColor: '#fff',
    vAxes: {
        0: {
            title: 'Mood',
            ticks: feels.map(e => ({
                v: e.score,
                f: e.emoji,
            })),
            textStyle: {
                fontSize: 28
            }
        },
        1: {
            title: 'Engagement',
            format: 'percent',
        }
    },
    series: {
        0: { targetAxisIndex: 0 },
        1: { targetAxisIndex: 1 },
    },
    animation: {
        duration: 500,
        easing: 'out',
        startup: true
    },
};

const cols: GoogleDataTableColumn[] = [{
    label: 'time',
    type: 'string'
}, {
    label: 'mood',
    type: 'number'
}, {
    label: 'engagement',
    type: 'number'
}, {
    type: 'string',
    role: 'tooltip',
} as any]

type Props = {
    stats: ScalarStats[],
    engagement?: {
        total: number
        stats?: ScalarStats[]
    }
    attendees: number
}

export const EngagementChart = ({
    stats,
    engagement,
    attendees
}: Props) => {
    const ref = useRef<any>(null)

    const currentMood = useMemo(() => {
        const mood = Math.floor(stats[stats?.length - 1]?.data)

        return feels.find(f => f.score === mood)?.emoji
    }, [stats])

    const currentEngagement = useMemo(() => {
        if (!engagement || !engagement.stats) return 0

        const last = engagement?.stats[engagement?.stats?.length - 1]?.data

        if (!last) return 0
        const eng = last / attendees

        return `${Math.round(eng * 100)}%`
    }, [engagement, attendees])

    const totalEngagement = useMemo(() => {
        return Math.round((engagement?.total ?? 0) / attendees * 100)
    }, [engagement, attendees])

    const data = useMemo(() => {
        const statsDates = stats.map(s => s.date)
        const engDates = engagement?.stats?.map(s => s.date) ?? []

        const dates = [...new Set([...statsDates, ...engDates])]

        const data = dates.map(d => ([
            format(new Date(d), 'dd MMM HH:mm'),
            (stats.find(s => isSameHour(new Date(s.date), new Date(d)))?.data ?? 0),
            (engagement?.stats?.find(e => isSameHour(new Date(e.date), new Date(d)))?.data ?? 0) / attendees
        ]))

        return [
            ["time", "mood", "engagement"],
            ...data,
        ]
    }, [stats, engagement, attendees])

    if (data.length <= 1) return null

    return (
        <div className="flex flex-col gap-8">
            <h2 className="text-center text-4xl">Event Satisfaction</h2>

            <Chart
                chartType="LineChart"
                width="100%"
                height="400px"
                data={data}
                columns={cols}
                options={options}
                ref={ref}
            />

            <div className="flex gap-6 pl-20 m-auto">
                {currentMood !== undefined && (
                    <div className="flex flex-col gap-4 items-center">
                        <span className="text-5xl h-12">{currentMood}</span>

                        <div className="font-bold">
                            Current Mood
                        </div>
                    </div>
                )}

                <div className="flex flex-col gap-4 items-center">
                    <span className="text-5xl h-12 text-green-700">{totalEngagement}%</span>

                    <div className="font-bold">
                        Total Engagement
                    </div>
                </div>

                <div className="flex flex-col gap-4 items-center">
                    <span className="text-5xl h-12 text-yellow-500">{currentEngagement}</span>

                    <div className="font-bold">
                        Current Engagement
                    </div>
                </div>
            </div>
        </div>
    )
}
