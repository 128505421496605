import { Sticker } from "../../types/Sticker"
import { FiPlus } from 'react-icons/fi'
import { ReactNode, useCallback, useEffect, useRef, useState } from "react"
import Upselled from "../UpsellDialog/Upselled"
import { FeatureEnabledResult } from "../../queries/useFeature"

type Props = Sticker & {
    clickable?: boolean
    onClick?: () => void
    icon?: ReactNode
    selectionEnabled?: FeatureEnabledResult
}

const LegendItem = ({
    url,
    icon,
    name,
    description,
    selectionEnabled,
    clickable,
    onClick
}: Props) => {
    const [descCollapsed, setDescCollapsed] = useState(true)
    const [collapsible, setCollapsible] = useState(false)
    const descriptionRef = useRef<HTMLDivElement>(null)

    const handleCollapseToggle = useCallback(() => setDescCollapsed(cur => !cur), [])

    useEffect(() => {
        function calculateCollapsible() {
            const { height } = descriptionRef.current?.getBoundingClientRect() ?? { height: 0 }
            const scrollHeight = descriptionRef.current?.scrollHeight ?? 0

            if (height < scrollHeight) {
                setCollapsible(true)
            } else if (descCollapsed) {
                setCollapsible(false)
            }
        }
        const obs = new ResizeObserver(() => {
            calculateCollapsible()
        })

        obs.observe(document.body)

        calculateCollapsible()

        return () => {
            obs.unobserve(document.body)
        }
    }, [descCollapsed])

    return (
        <div className="flex gap-2 font-sans items-start text-start p-2">
            <div className="bg-[#e8e8e8] relative transition aspect-square rounded-lg flex justify-center items-center p-4 md:w-28 md:h-28 w-24 h-24 shrink-0">
                {icon ? icon : (
                    <img src={url} alt={name} className="object-contain h-full" />
                )}

                {clickable && (
                    <Upselled
                        enabled={selectionEnabled}
                        className="-top-2 -right-2 absolute "
                    >
                        <button
                            type="submit"
                            className="bg-[#008000] p-1 text-white rounded-full transition hover:scale-150"
                            onClick={() => selectionEnabled && onClick && onClick()}
                        >
                            <FiPlus />
                        </button>
                    </Upselled>
                )}
            </div>

            <div className="flex flex-col md:gap-4 gap-1 py-0 px-3">
                <h3 className="text-xl font-bold">
                    {name}
                </h3>

                <div>
                    <div ref={descriptionRef} className={`text-sm ${descCollapsed && 'line-clamp-3'} md:line-clamp-none`}>
                        {description}
                    </div>
                    {collapsible && (
                        <>
                            {' '}
                            <button
                                onClick={handleCollapseToggle}
                                className="md:hidden inline-block font-bold text-sm"
                            >
                                {descCollapsed ? "more..." : "less"}
                            </button>
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}

export default LegendItem