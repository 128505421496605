import { Dialog } from "@mui/material"
import { ReactNode } from "react"
import Carousel from "./OnboardingCarousel"
import { IoMdClose } from "react-icons/io"
import cx from 'classnames'

type Props = {
    open?: boolean
    onClose: () => void
}

type CarouselPayload = {
    image: string,
    text: ReactNode,
    imgClass?: string
}

const items: CarouselPayload[] = [{
    image: '/assets/product-carousel/1.png',
    text: <>The sticker sheets make conversations easier at your event</>
}, {
    image: '/assets/product-carousel/2.png',
    text: <>This site contains additional information - revisit it <span className="font-bold">at any time</span></>
}, {
    image: '/assets/product-carousel/3.png',
    text: <>Pick stickers that represent you and put them somewhere visible - keep the sheet if you change your mind</>
}, {
    image: '/assets/product-carousel/4.png',
    text: <>Start conversations, make sure your stickers are visible to others</>
}]

export default function ProductOnboardingDialog({
    open,
    onClose
}: Props) {
    return (
        <Dialog
            open={!!open}
            onClose={onClose}
            maxWidth="sm"
            fullWidth
            classes={{
                'paper': '!rounded-2xl'
            }}
        >
            <div className="p-0 relative bg-white relative flex flex-col overflow-hidden relative">
                <button className="absolute md:right-8 right-4 md:top-8 top-4 z-10" onClick={onClose}>
                    <IoMdClose className="text-gray-300" size={25} />
                </button>
                <Carousel>
                    {items.map((it, i) => (
                        <div className="flex flex-col gap-4 min-w-[100%] snap-center" key={i}>
                            <div className={cx("object-contain flex-1 bg-[#f6f6f6] flex items-center justify-center", it.imgClass)}>
                                <img src={it.image} alt="" />
                            </div>

                            <div className="h-[150px] max-w-[320px] p-4 mx-auto flex items-center">
                                <h2 className="text-center md:text-lg">{it.text}</h2>
                            </div>
                        </div>
                    ))}
                </Carousel>
            </div>
        </Dialog>
    )
}