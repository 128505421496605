import { useState } from "react"
import { EngagementChart } from "../../components/admin/EngagementChart"
import { StickerMultiselect } from "../../components/Filters/StickerMultiselect"
import { GlobalLoader } from "../../components/GlobalLoader"
import { useLegend } from "../../queries/legend/useLegend"
import { useRatingStats } from "../../queries/useRatingStats"
import { useStickers } from "../../queries/useStickers"

const placeholderArr: any[] = []

const EngagementPage = () => {
    const [selectedStickers, setSelectedStickers] = useState<string[]>([])
    const [stats, loading] = useRatingStats(selectedStickers)
    const [settings, loadingSettings] = useLegend()
    const [stickers, loadingStickers] = useStickers()

    return (
        <GlobalLoader loading={loading || loadingSettings || loadingStickers}>
            <div className="pt-16 flex flex-col px-12 gap-4">
                <StickerMultiselect value={selectedStickers} stickers={stickers!} onSelect={setSelectedStickers} />
                {!settings?.attendees && (
                    <h2 className="text-center">In order to see engagement stats, specify attendees amount in settings.</h2>
                )}

                {!stats?.engagement?.stats?.length && (
                    <h2 className="text-center">No events found</h2>
                )}

                {settings?.attendees && (
                    <EngagementChart stats={stats?.rating ?? placeholderArr} engagement={stats?.engagement} attendees={settings!.attendees} />
                )}
            </div>
        </GlobalLoader>
    )
}

export default EngagementPage
