import { isValid } from "date-fns"

export type ValidatorFn<T = any> = (val: any, item?: Partial<T>) => { isValid: boolean, message?: string }

export const required: ValidatorFn = (val: any) => {
	if (!val) {
		return {
			isValid: false,
			message: 'This field is required'
		}
	}

	return {
		isValid: true
	}
}

export const email: ValidatorFn = (val: string) => {
	if (/\S+@\S+\.\S+/.test(val)) {
		return {
			isValid: true
		}
	}

	return {
		isValid: false,
		message: 'Email should be provided in valid format'
	}
}

export const positiveNumber: ValidatorFn = (val: string) => {
	if (isNaN(val as unknown  as number) || (val as unknown as number) <= 0) {
		return {
			isValid: false,
			message: 'Value should be a positive number'
		}
	}

	return {
		isValid: true
	}
}

export const date: ValidatorFn = (val: any) => {
	if (isValid(val)) return { isValid: true }

	return {
		isValid: false,
		message: 'Invalid value'
	}
}

export const timeRelationStart = (fieldName: string): ValidatorFn => {
	return (val: Date, item: any) => {
		if (!item?.[fieldName] || !val || val.getTime() <= item?.[fieldName]?.getTime()) {
			return {
				isValid: true
			}
		}

		return {
			isValid: false,
			message: 'Start time should be less then end time'
		}
	}
}

export const timeRelationEnd = (fieldName: string): ValidatorFn => {
	return (val: Date, item: any) => {
		if (!item?.[fieldName] || !val || val.getTime() >= item?.[fieldName]?.getTime()) {
			return {
				isValid: true
			}
		}

		return {
			isValid: false,
			message: 'End time should be more then start time'
		}
	}
}
